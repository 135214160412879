import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import entitytypeCategory from 'config/entitytypeCategory';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import TypeDropDown from 'containers/Entitylink/TypeDropDown';
import Authentication from 'utils/Authentication';
import m from '../../messages/de.json';
import cs from './EntitylinkForm.module.scss';

const validate = (values) => {
  const errors = {};

  if (!values.numbersustainable) {
    errors.numbersustainable = 'Bedarf nachhaltig ist ein Pflichtfeld';
  }

  if (!values.numberunsustainable) {
    errors.numberunsustainable = 'Bedarf nicht nachhaltig ist ein Pflichtfeld';
  }

  if (!values.type) {
    errors.type = 'Richtung ist ein Pflichtfeld';
  }

  if (!values.linktoentityid) {
    errors.linktoentityid = 'Entität ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  entityid: 0,
  numbersustainable: ''
};

const propTypes = {
  entitylink: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  typeFilter: PropTypes.array
};
export default function EntitylinkForm({
  entityid,
  entitylink,
  isNew = false,
  onClose,
  onSubmit,
  typeFilter
}) {
  // if (isNew) {
  initialValues.entityid = entityid;
  // }

  const formik = useFormik({
    initialValues: entitylink || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={
                formik.isSubmitting ||
                !Authentication.hasUserRole('uiadmin_admin')
              }
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.type}
            label='Richtung'
            name='type'
            onChange={formik.handleChange}
            value={formik.values.type}
          >
            <TypeDropDown typeFilter={typeFilter} />
          </FormItem>
          <FormItem
            error={formik.errors.linktoentityid}
            label='für die Transformation benötigte Resourcen oder Produkte'
            name='linktoentityid'
            onChange={formik.handleChange}
            value={formik.values.linktoentityid}
          >
            <EntityDropDown filterEntitytype={entitytypeCategory.product} />
          </FormItem>
          <FormItem
            error={formik.errors.numberunsustainable}
            label='Bedarf/Erzeugung bei einem nicht nachhaltigen Zustand der Entität'
            name='numberunsustainable'
            onChange={formik.handleChange}
            value={formik.values.numberunsustainable}
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.numbersustainable}
            label='Bedarf/Erzeugung bei einem nachhaltigen Zustand der Entität'
            name='numbersustainable'
            onChange={formik.handleChange}
            value={formik.values.numbersustainable}
          >
            <TextInput />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EntitylinkForm.propTypes = propTypes;
