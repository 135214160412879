import {useQuery, useMutation} from '@apollo/react-hooks';
import gql from 'graphql-tag';
import {useSnackbar, closeSnackbar} from 'notistack';
import React from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import {useGameSelectContext} from 'containers/GameSelect/GameSelectProvider';
import m from '../../messages/de.json';
import GameperiodsContent from './GameperiodsContent';

const actionGameperiodCreationMutation = gql`
  mutation actionGameperiodCreation($gameid: Int!) {
    actionGameperiodCreation(gameid: $gameid) {
      code
      message
    }
  }
`;

const query = gql`
  query getGameperiods($gameid: Int!) {
    page: gameperiod_aggregate(limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    gameperiods: gameperiod(
      order_by: {id: desc}
      where: {gameid: {_eq: $gameid}}
    ) {
      id
      gameid
      period
      name
      closed
      taskstatus
      erda
    }

    game: game_by_pk(id: $gameid) {
      id
      execution
      taskstatus
    }
  }
`;

export default function Gameperiods() {
  const {game} = useGameSelectContext();

  const {
    data: resultGameperiods,
    error,
    loading,
    refetch
  } = useQuery(query, {
    pollInterval: 60000,
    variables: {gameid: game.id}
  });
  const {enqueueSnackbar} = useSnackbar();

  const [onGameperiodCreationMutation] = useMutation(
    actionGameperiodCreationMutation
  );

  async function onGameperiodCreation(game) {
    onGameperiodCreationMutation({variables: {gameid: game.id}})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.GameperiodCreationDialog.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(
          `${m.GameperiodCreationDialog.rejected} (Error: ${error})`,
          {
            variant: 'error',
            action: (key) => (
              <CloseButtonSmall key={key} onClick={closeSnackbar} />
            )
          }
        );
      });
  }

  return (
    <AsyncContent error={error} loading={loading}>
      <GameperiodsContent
        onGameperiodCreation={onGameperiodCreation}
        refetch={refetch}
        resultGameperiods={resultGameperiods}
      />
    </AsyncContent>
  );
}
