import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import cx from 'classnames';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router-dom';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import TableFixedInfiniteLoading from 'components/TableFixedInfiniteLoading/TableFixedInfiniteLoading';
import cs from 'containers/Landscaperegions/LandscaperegionsTable.module.scss';
import Formater from 'utils/Formater';
import {SurveysTable as labels} from './labels';

const propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  loadingMore: PropTypes.bool,
  onActionSurveyStatusChange: PropTypes.func,
  onFetchNextPage: PropTypes.func,
  selectedId: PropTypes.string,
  surveys: PropTypes.array
};

function SurveysTable({
  className,
  loading,
  loadingMore,
  onActionSurveyStatusChange,
  onFetchNextPage,
  selectedId,
  surveys
}) {
  const {enqueueSnackbar} = useSnackbar();

  function onClickActionSurveyStatusChange(surveyid, statusNext) {
    return onActionSurveyStatusChange(surveyid, statusNext)
      .then(() => {
        enqueueSnackbar(labels.actionStatusChange.success, {
          variant: 'success'
        });
      })
      .catch((e) => {
        enqueueSnackbar(labels.actionStatusChange.error + ` (${e})`, {
          variant: 'error',
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        });
      });
  }

  function renderTableHead() {
    return (
      <TableHead component='div'>
        <TableRow component='div'>
          <TableCell component='div'>&nbsp;</TableCell>
          <TableCell component='div'>
            {labels.header.questionnaireName}
          </TableCell>
          <TableCell component='div'>{labels.header.start}</TableCell>
          <TableCell component='div'>
            {labels.header.participantsTotal}
          </TableCell>
          <TableCell component='div'>
            {labels.header.participantsClosed}
          </TableCell>
          <TableCell component='div'>{labels.header.status}</TableCell>
        </TableRow>
      </TableHead>
    );
  }

  return (
    <Paper className={cx(cs.root, className)}>
      <TableFixedInfiniteLoading
        loading={loading}
        loadingMore={loadingMore}
        onFetchNextPage={onFetchNextPage}
      >
        {() => (
          <>
            {renderTableHead()}
            <TableBody component='div'>
              {surveys.map((item) => (
                <TableRow
                  key={item.id}
                  className={cx(cs.rowLink, {
                    [cs.rowLink_selected]: item.id === parseFloat(selectedId)
                  })}
                  component={(props) => (
                    <Link to={`/surveys/${item.id}`} {...props} />
                  )}
                  hover
                >
                  <TableCell
                    className={cs.entityColumn}
                    component='div'
                    onClick={(e) => e.preventDefault()}
                  >
                    {item.status === 'inprogress' ? (
                      <Button
                        aria-label='feedback-changestatus'
                        color='primary'
                        onClick={() =>
                          onClickActionSurveyStatusChange(item.id, 'closed')
                        }
                        size='small'
                        startIcon={<StopCircleIcon />}
                        variant='contained'
                      >
                        Beenden
                      </Button>
                    ) : item.status === 'open' ? (
                      <Button
                        aria-label='feedback-changestatus'
                        color='primary'
                        onClick={() =>
                          onClickActionSurveyStatusChange(item.id, 'inprogress')
                        }
                        size='small'
                        startIcon={<PlayCircleFilledWhiteIcon />}
                        variant='contained'
                      >
                        Starten
                      </Button>
                    ) : (
                      <Button
                        aria-label='feedback-changestatus'
                        color='secondary'
                        onClick={() =>
                          onClickActionSurveyStatusChange(item.id, 'inprogress')
                        }
                        size='small'
                        startIcon={<PlayCircleFilledWhiteIcon />}
                      >
                        Fortführen
                      </Button>
                    )}
                  </TableCell>
                  <TableCell className={cs.entityColumn} component='div'>
                    {item.questionnaire.name}
                  </TableCell>
                  <TableCell className={cs.gameperiodColumn} component='div'>
                    {Formater.postgresTsFormat(item.tsstart)}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={cs.entityColumn}
                    component='div'
                  >
                    {item.participantsTotal?.aggregate?.count}
                  </TableCell>
                  <TableCell
                    align='right'
                    className={cs.entityColumn}
                    component='div'
                  >
                    {item.participantsClosed?.aggregate?.count}
                  </TableCell>
                  <TableCell className={cs.roleColumn} component='div'>
                    <Chip
                      color={item.status !== 'closed' ? 'primary' : 'secondary'}
                      label={labels.status[item.status]}
                      size='small'
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </>
        )}
      </TableFixedInfiniteLoading>
    </Paper>
  );
}

SurveysTable.propTypes = propTypes;
export default SurveysTable;
