import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import cx from 'classnames';
import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import OrganisationDropDown from 'containers/Userlist/OrganisationDropDown';
import UserroleDropDown from 'containers/Userlist/UserroleDropDown';
import m from '../../messages/de.json';
import cs from '../EntityroleForm/EntityroleForm';
import labels from '../EntityroleForm/labels';

const validate = (values) => {
  const errors = {};

  if (!values.longname) {
    errors.longname = 'Name ist ein Pflichtfeld';
  }

  if (!values.email) {
    errors.email = 'E-Mail ist ein Pflichtfeld';
  }

  if (!values.userrole || values.userrole <= 0) {
    errors.userrole = 'Berechtigungs-Rolle ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {entityid: 0};

const propTypes = {
  className: PropTypes.string,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onSubmitFinish: PropTypes.func,
  userlist: PropTypes.object
};
export default function UserlistForm({
  className,
  onCancel,
  onSubmit,
  onSubmitFinish,
  userlist
}) {
  const formik = useFormik({
    initialValues: userlist || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();

  function onSubmitForm(values) {
    return onSubmit(values)
      .then((data) => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {
          variant: 'success'
        });
        formik.resetForm();
        onSubmitFinish();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} (Error: ${error})`, {
          variant: 'error',
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        });
      });
  }

  const isCreateForm = formik.values.id === undefined;

  return (
    <div className={cx(className, cs.root)}>
      <Typography gutterBottom variant='h6'>
        {isCreateForm ? m.UserlistForm.title.create : m.UserlistForm.title.edit}
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onCancel} variant='outlined'>
              {labels.cancel}
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting || !formik.isValid}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.longname}
            label='Name'
            name='longname'
            onChange={formik.handleChange}
            value={formik.values.longname}
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.email}
            label='E-Mail (muss für jeden Benutzer eindeutige sein)'
            name='email'
            onChange={formik.handleChange}
            value={formik.values.email}
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.organisationid}
            label='Organisation (Daten-Berechtigung)'
            name='organisationid'
            onChange={formik.handleChange}
            value={formik.values.organisationid}
          >
            <OrganisationDropDown />
          </FormItem>
          <FormItem
            error={formik.errors.userrole}
            label='Berechtigungs-Rolle (Funktions-Berechtigung)'
            name='userrole'
            onChange={formik.handleChange}
            value={formik.values.userrole}
          >
            <UserroleDropDown />
          </FormItem>

          {isCreateForm && (
            <>
              <br />
              <br />
              <FormItem
                error={formik.errors.pwd}
                label='Neues Passwort Definieren'
                name='pwd'
                onChange={formik.handleChange}
                value='weltklimaspiel1758'
              >
                <TextInput readonly />
              </FormItem>
            </>
          )}
          <br />
          <Typography>
            <b>Organisation:</b> Benutzer können einer Organisation zugeordnet
            werden.
            <br />
            Dann können sie nur die Spiele der jeweiligen Organisation sehen.
            <br />
            Ein Benutzer ohne Organisationszuordnung sieht alle Spiele.
          </Typography>
          <br />
          <Typography>
            <b>Berechtigungs-Rolle:</b> definiert welche Funktionen verwendet
            <br />
            werden können. Z.B. nur der Administrator kann Entitäten, <br />
            Benutzer und Organisationen bearbeiten.
          </Typography>
        </FormColumn>
      </Form>
    </div>
  );
}
UserlistForm.propTypes = propTypes;
