import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import entitytypeCategory from 'config/entitytypeCategory';
import EntitycategoryDropDown from 'containers/Entity/EntitycategoryDropDown';
import EntitytypeDropDown from 'containers/Entity/EntitytypeDropDown';
import StatustypeDropDown from 'containers/Entity/StatustypeDropDown';
import Authentication from 'utils/Authentication';
import m from '../../messages/de.json';
import cs from './EntityForm.module.scss';
import labels from './labels';

function isANumber(str) {
  return !/\D/.test(str);
}

// eslint-disable-next-line func-style
const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name ist ein Pflichfeld';
  }

  if (!values.entitytype) {
    errors.entitytype = 'Typ ist ein Pflichfeld';
  }

  if (!isANumber(values.buildingcosts)) {
    errors.buildingcosts = 'mindestens 0 und nur Ganzzahlen';
  }

  if (values.salesprice === '0' || !isANumber(values.salesprice)) {
    errors.salesprice =
      'Verklaufspreis muss mindestens 1 sein und nur Ganzzahlen.';
  }

  return errors;
};

const initialValues = {
  name: '',
  salesprice: 1,
  buildingcosts: 0
};

const propTypes = {
  entity: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
export default function EntityForm({entity, onClose, onSubmit}) {
  const formik = useFormik({
    initialValues: entity || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar('Daten erfolgreich gespeichert', {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${labels.error} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  const isBuilding = entitytypeCategory.building.includes(entity?.entitytype);
  const isProduct = entitytypeCategory.product.includes(entity?.entitytype);
  const isResearch = entitytypeCategory.research.includes(entity?.entitytype);

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={
                formik.isSubmitting ||
                !Authentication.hasUserRole('uiadmin_admin')
              }
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.name}
            label='Name'
            name='name'
            onChange={formik.handleChange}
            value={formik.values.name}
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.entitytype}
            label='Typ'
            name='entitytype'
            onChange={formik.handleChange}
            value={formik.values.entitytype}
          >
            <EntitytypeDropDown />
          </FormItem>
          {isBuilding && (
            <FormItem
              error={formik.errors.statustype}
              label='Bezeichnung Status in UI-Play'
              name='statustype'
              onChange={formik.handleChange}
              value={
                formik.values.statustype
                  ? formik.values.statustype.toString()
                  : formik.values.statustype
              }
            >
              <StatustypeDropDown />
            </FormItem>
          )}
          {!isResearch && (
            <>
              <FormItem
                error={formik.errors.ghgemissionperperiodsustainable}
                label='Treibhausgas pro Spielrunde für den Status nachhaltig'
                name='ghgemissionperperiodsustainable'
                onChange={formik.handleChange}
                value={
                  formik.values.ghgemissionperperiodsustainable
                    ? formik.values.ghgemissionperperiodsustainable.toString()
                    : formik.values.ghgemissionperperiodsustainable
                }
              >
                <TextInput />
              </FormItem>
              <FormItem
                error={formik.errors.ghgemissionperperiodunsustainable}
                label='Treibhausgas pro Spielrunde für den Status nicht nachhaltig'
                name='ghgemissionperperiodunsustainable'
                onChange={formik.handleChange}
                value={
                  formik.values.ghgemissionperperiodunsustainable
                    ? formik.values.ghgemissionperperiodunsustainable.toString()
                    : formik.values.ghgemissionperperiodunsustainable
                }
              >
                <TextInput />
              </FormItem>
            </>
          )}
          {isBuilding && (
            <>
              <FormItem
                error={formik.errors.earningperperiodsustainable}
                label='Profit pro Spielrunde für den Status nachhaltig'
                name='earningperperiodsustainable'
                onChange={formik.handleChange}
                value={
                  formik.values.earningperperiodsustainable
                    ? formik.values.earningperperiodsustainable.toString()
                    : formik.values.earningperperiodsustainable
                }
              >
                <TextInput />
              </FormItem>
              <FormItem
                error={formik.errors.earningperperiodunsustainable}
                label='Profit pro Spielrunde für den Status nicht nachhaltig'
                name='earningperperiodunsustainable'
                onChange={formik.handleChange}
                value={
                  formik.values.earningperperiodunsustainable
                    ? formik.values.earningperperiodunsustainable.toString()
                    : formik.values.earningperperiodunsustainable
                }
              >
                <TextInput />
              </FormItem>
            </>
          )}
          {isBuilding && (
            <FormItem
              error={formik.errors.buildingcosts}
              label='Errichtungskosten'
              name='buildingcosts'
              onChange={formik.handleChange}
              value={
                formik.values.buildingcosts
                  ? formik.values.buildingcosts.toString()
                  : formik.values.buildingcosts
              }
            >
              <TextInput />
            </FormItem>
          )}
          {isBuilding && (
            <FormItem
              error={formik.errors.updatetosustainablecosts}
              label='Umbaukosten zu einer nachhaltigen Entität'
              name='updatetosustainablecosts'
              onChange={formik.handleChange}
              value={
                formik.values.updatetosustainablecosts
                  ? formik.values.updatetosustainablecosts.toString()
                  : formik.values.updatetosustainablecosts
              }
            >
              <TextInput />
            </FormItem>
          )}
          {isBuilding && (
            <FormItem
              error={formik.errors.destructioncosts}
              label='Abbruchkosten'
              name='destructioncosts'
              onChange={formik.handleChange}
              value={
                formik.values.destructioncosts
                  ? formik.values.destructioncosts.toString()
                  : formik.values.destructioncosts
              }
            >
              <TextInput />
            </FormItem>
          )}
          {isProduct || isResearch ? (
            <FormItem
              error={formik.errors.salesprice}
              label='Verkaufspreis (mind. 1)'
              name='salesprice'
              onChange={formik.handleChange}
              value={
                formik.values.salesprice
                  ? formik.values.salesprice.toString()
                  : formik.values.salesprice
              }
            >
              <TextInput />
            </FormItem>
          ) : (
            ''
          )}
          {entity?.entitytype === 'ghost' && (
            <FormItem
              error={formik.errors.salesprice}
              label='Bevölkerung'
              name='population'
              onChange={formik.handleChange}
              value={
                formik.values.population
                  ? formik.values.population.toString()
                  : formik.values.population
              }
            >
              <TextInput />
            </FormItem>
          )}
          {entity?.entitytype === 'city' && (
            <>
              <FormItem
                error={formik.errors.salesprice}
                label='Wohnraum'
                name='accommodationcapacity'
                onChange={formik.handleChange}
                value={
                  formik.values.accommodationcapacity
                    ? formik.values.accommodationcapacity.toString()
                    : formik.values.accommodationcapacity
                }
              >
                <TextInput />
              </FormItem>
              <FormItem>
                <span>Für Städte müssen keine Lohnkosten bezahlt werden.</span>
              </FormItem>
            </>
          )}
          <FormItem
            error={formik.errors.icon}
            label='Dateiname des Icons für UI-Play'
            name='icon'
            onChange={formik.handleChange}
            value={formik.values.icon}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            error={formik.errors.icon}
            label='Dateiname Nachhaltigkeits-Icons für UI-Play (optional)'
            name='iconsustainable'
            onChange={formik.handleChange}
            value={formik.values.iconsustainable}
          >
            <TextInput readonly />
          </FormItem>
          {isResearch && (
            <>
              <FormItem
                error={formik.errors.cardfile}
                label='Dateiname Entwicklungskarte für UI-Play'
                name='cardfile'
                onChange={formik.handleChange}
                value={formik.values.cardfile}
              >
                <TextInput readonly />
              </FormItem>
              <FormItem
                error={formik.errors.entitycategory}
                label='Kategorie (definiert in welchem Karussell in UI-Play die Karte angezeigt wird)'
                name='entitycategory'
                onChange={formik.handleChange}
                value={formik.values.entitycategory}
              >
                <EntitycategoryDropDown />
              </FormItem>
              <FormItem
                error={formik.errors.settings}
                label='Zusätzliche Settings im JSON Format (nur für Experten)'
                name='settings'
                onChange={formik.handleChange}
                value={JSON.stringify(formik.values.settings)}
              >
                <TextArea readonly />
              </FormItem>
            </>
          )}
          <FormItem
            error={formik.errors.code}
            label='System-Code'
            name='code'
            onChange={formik.handleChange}
            value={
              formik.values.code
                ? formik.values.code.toString()
                : formik.values.code
            }
          >
            <TextInput readonly />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EntityForm.propTypes = propTypes;
