import {Typography} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import DateUtils from 'utils/DateUtils';
import labels from './labels';

const initialValues = {
  txt: ''
};

const propTypes = {
  economictranslog: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
export default function EconomictranslogForm({
  economictranslog,
  onClose,
  onSubmit
}) {
  const formik = useFormik({
    initialValues: economictranslog || initialValues,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar('Daten erfolgreich gespeichert', {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${labels.error} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  return (
    <div className={cs.root}>
      <Typography gutterBottom variant='h6'>
        Buchung-Nr: {economictranslog?.id}
      </Typography>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Schliessen
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            label='Spielperiode'
            name='gameperiodid'
            onChange={formik.handleChange}
            value={formik.values?.gameperiod?.name}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Buchungstyp'
            name='transactiontype'
            onChange={formik.handleChange}
            value={formik.values?.transactiontype}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='von'
            name='fromeconomicacc'
            onChange={formik.handleChange}
            value={formik.values?.fromeconomicacc?.player?.title}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='nach'
            name='toeconomicacc'
            onChange={formik.handleChange}
            value={formik.values?.toeconomicacc?.player?.title}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Betrag [$]'
            name='amountmoney'
            onChange={formik.handleChange}
            value={formik.values?.amountmoney}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Menge'
            name='amountentity'
            onChange={formik.handleChange}
            value={formik.values?.amountentity}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Entität'
            name='entity'
            onChange={formik.handleChange}
            value={formik.values?.toeconomicacc?.entity?.name}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Beschreibung'
            name='txt'
            onChange={formik.handleChange}
            value={formik.values?.txt}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Status'
            name='status'
            onChange={formik.handleChange}
            value={formik.values?.status}
          >
            <TextInput readonly />
          </FormItem>
          <FormItem
            label='Zeitpunkt'
            name='erda'
            onChange={formik.handleChange}
            value={
              formik.values?.erda
                ? DateUtils.formatDateTime(formik.values?.erda)
                : null
            }
          >
            <TextInput readonly />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EconomictranslogForm.propTypes = propTypes;
