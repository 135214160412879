import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import labels from 'components/EconomictradeForm/labels';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextInput from 'components/TextInput/TextInput';
import entitytypeCategory from 'config/entitytypeCategory';
import EntityDropDown from 'containers/Entitylink/EntityDropDown';
import PlayerDropDown from 'containers/Entityrole/PlayerDropDown';
import m from '../../messages/de.json';

// eslint-disable-next-line func-style
const validate = (values) => {
  const errors = {};

  if (!values.playerid || values.playerid === '0') {
    errors.playerid = 'SpielerIn ist ein Pflichtfeld';
  }

  if (!values.amountentity || !parseInt(values.amountentity)) {
    errors.amountentity = 'Neuer Kontostand ist ein Pflichtfeld';
  }

  if (!values.txt) {
    errors.txt = 'Beschreibung ist ein Pflichtfeld';
  }

  if (!values.entityid || values.entityid === '0') {
    errors.entityid = 'Produkt ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  txt: ''
};

const propTypes = {
  economictranslog: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
export default function EconomicStockTakingForm({
  economictranslog,
  onClose,
  onSubmit
}) {
  const formik = useFormik({
    initialValues: economictranslog || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar('Daten erfolgreich gespeichert', {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${labels.error} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.playerid}
            label='SpielerIn'
            name='playerid'
            onChange={formik.handleChange}
            value={
              formik.values.playerid
                ? formik.values.playerid.toString()
                : formik.values.playerid
            }
          >
            <PlayerDropDown />
          </FormItem>
          <FormItem
            error={formik.errors.entityid}
            label='Produkt'
            name='entityid'
            onChange={formik.handleChange}
            value={
              formik.values.entityid
                ? formik.values.entityid.toString()
                : formik.values.entityid
            }
          >
            <EntityDropDown filterEntitytype={entitytypeCategory.product} />
          </FormItem>
          <FormItem
            error={formik.errors.amountentity}
            label='Neuer Kontostand'
            name='amountentity'
            onChange={formik.handleChange}
            value={
              formik.values.amountentity
                ? formik.values.amountentity.toString()
                : formik.values.amountentity
            }
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.txt}
            label='Bemerkung'
            name='txt'
            onChange={formik.handleChange}
            value={
              formik.values.txt
                ? formik.values.txt.toString()
                : formik.values.txt
            }
          >
            {formik.values.id === undefined ? (
              <TextInput />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
EconomicStockTakingForm.propTypes = propTypes;
