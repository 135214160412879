import CloseIcon from '@mui/icons-material/Close';
import {IconButton} from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function CloseButtonSmall({key, onClick}) {
  return (
    <IconButton
      aria-label='close'
      color='inherit'
      onClick={() => onClick(key)}
      size='small'
    >
      <CloseIcon fontSize='small' />
    </IconButton>
  );
}

CloseButtonSmall.propTypes = {
  key: PropTypes.func.toString.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CloseButtonSmall;
