import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import DatePicker from 'components/DatePicker/DatePicker';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import TextArea from 'components/TextArea/TextArea';
import TextInput from 'components/TextInput/TextInput';
import ExecutionDropDown from 'containers/Game/ExecutionDropDown';
import OrganisationDropDownOrgPermission from 'containers/Game/OrganisationDropDownOrgPermission';
import TemplateGameperiodDropDown from 'containers/Game/TemplateGameperiodDropDown';
import m from '../../messages/de.json';

const validate = (values) => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Titel ist ein Pflichfeld';
  }

  if (!values.initialghglevel) {
    errors.initialghglevel = 'Initales Treibhausgas Leve ist ein Pflichfeld';
  }

  if (!values.execution) {
    errors.execution = 'Spielstatus ist ein Pflichfeld';
  }

  if (!values.organisationid || values.organisationid <= 0) {
    errors.organisationid = 'Organisation';
  }

  if (!values.templategameperiodid || values.templategameperiodid <= 0) {
    errors.templategameperiodid = 'Vorlage Spielperiode';
  }

  if (!values.executionfrom) {
    errors.executionfrom = 'Spielstart ist ein Pflichfeld';
  }

  if (
    !values.researchcardpurchaseperperiode ||
    !(
      values.researchcardpurchaseperperiode >= 1 &&
      values.researchcardpurchaseperperiode <= 100
    )
  ) {
    errors.researchcardpurchaseperperiode =
      'Entwicklungskarten Kauf Pro Periode und Spieler (Wertebereich: 1 - 100))';
  }

  return errors;
};

const initialValues = {
  title: '',
  initialghglevel: '2050',
  researchcardpurchaseperperiode: '3'
};

const propTypes = {
  game: PropTypes.object,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func
};
export default function GameForm({game, onClose, onSubmit}) {
  const formik = useFormik({
    initialValues: game || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.title}
            label='Titel'
            name='title'
            onChange={formik.handleChange}
            value={
              formik.values.title
                ? formik.values.title.toString()
                : formik.values.title
            }
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.organisationid}
            label='Organisation'
            name='organisationid'
            onChange={formik.handleChange}
            value={
              formik.values.organisationid
                ? formik.values.organisationid.toString()
                : formik.values.organisationid
            }
          >
            <OrganisationDropDownOrgPermission />
          </FormItem>
          <FormItem
            error={formik.errors.templategameperiodid}
            label='Vorlage Spielperiode (für Hexagone, Bauwerke und Steuereinstellungen)'
            name='templategameperiodid'
            onChange={formik.handleChange}
            value={
              formik.values.templategameperiodid
                ? formik.values.templategameperiodid.toString()
                : formik.values.templategameperiodid
            }
          >
            {formik.values.id === undefined ? (
              <TemplateGameperiodDropDown />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.initialghglevel}
            label='Initales Treibhasgas Level [Giga Tonnen]'
            name='initialghglevel'
            onChange={formik.handleChange}
            value={
              formik.values.initialghglevel
                ? formik.values.initialghglevel.toString()
                : formik.values.initialghglevel
            }
          >
            {formik.values.id === undefined ? (
              <TextInput />
            ) : (
              <TextInput readonly />
            )}
          </FormItem>
          <FormItem
            error={formik.errors.researchcardpurchaseperperiode}
            label='Entwicklungskarten Kauf Pro Periode und Spieler (kann während dem Spiel angepasst werden)'
            name='researchcardpurchaseperperiode'
            onChange={formik.handleChange}
            value={
              formik.values.researchcardpurchaseperperiode
                ? formik.values.researchcardpurchaseperperiode.toString()
                : formik.values.researchcardpurchaseperperiode
            }
          >
            {formik.values.id === undefined ? <TextInput /> : <TextInput />}
          </FormItem>
          <FormItem
            error={formik.errors.executionfrom}
            label='Spielstart'
            name='executionfrom'
            onChange={formik.handleChange}
            value={
              formik.values.executionfrom
                ? formik.values.executionfrom.toString()
                : formik.values.executionfrom
            }
          >
            <DatePicker />
          </FormItem>
          <FormItem
            error={formik.errors.execution}
            label='Spielstatus (UI-Play Login nur möglich bei Status "Spiel läuft")'
            name='execution'
            onChange={formik.handleChange}
            value={
              formik.values.execution
                ? formik.values.execution.toString()
                : formik.values.execution
            }
          >
            <ExecutionDropDown />
          </FormItem>
          <FormItem
            label='Anzahl SpielerInnen'
            name='numberofplayers'
            onChange={formik.handleChange}
            value={
              formik.values.numberofplayers
                ? formik.values.numberofplayers.toString()
                : formik.values.numberofplayers
            }
          >
            <TextInput />
          </FormItem>
          <FormItem
            label='Bemerkung'
            name='note'
            onChange={formik.handleChange}
            value={
              formik.values.note
                ? formik.values.note.toString()
                : formik.values.note
            }
          >
            <TextArea />
          </FormItem>
        </FormColumn>
      </Form>
    </div>
  );
}
GameForm.propTypes = propTypes;
