import {useFormik} from 'formik';
import {useSnackbar, closeSnackbar} from 'notistack';
import PropTypes from 'prop-types';
import React from 'react';
import Button from 'components/Button/Button';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import cs from 'components/EntityForm/EntityForm.module.scss';
import Form from 'components/Form/Form';
import FormColumn from 'components/Form/FormColumn';
import FormItem from 'components/Form/FormItem';
import RecordInfo from 'components/Form/RecordInfo';
import TextInput from 'components/TextInput/TextInput';
import m from '../../messages/de.json';

const validate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Name ist ein Pflichtfeld';
  }

  return errors;
};

const initialValues = {
  gameid: ''
};

const propTypes = {
  gameperiod: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};
export default function GameperiodForm({gameperiod, onClose, onSubmit}) {
  const formik = useFormik({
    initialValues: gameperiod || initialValues,
    validate,
    onSubmit: onSubmitForm,
    enableReinitialize: true
  });

  const {enqueueSnackbar} = useSnackbar();
  async function onSubmitForm(values) {
    return onSubmit(values)
      .then(() => {
        enqueueSnackbar(m.dataHandlingPromise.fulfilled, {variant: 'success'});
        onClose();
      })
      .catch((e) =>
        enqueueSnackbar(`${m.dataHandlingPromise.rejected} ${e?.message}`, {
          variant: 'error',
          persist: true,
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        })
      );
  }

  return (
    <div className={cs.root}>
      <Form
        actions={
          <>
            <Button onClick={onClose} variant='outlined'>
              Abbrechen
            </Button>
            <Button
              color='primary'
              disabled={formik.isSubmitting}
              type='submit'
              variant='contained'
            >
              {m.dataHandlingForm.save}
            </Button>
          </>
        }
        onSubmit={formik.handleSubmit}
      >
        <FormColumn>
          <FormItem
            error={formik.errors.name}
            label='Name'
            name='name'
            onChange={formik.handleChange}
            value={
              formik.values.name
                ? formik.values.name.toString()
                : formik.values.name
            }
          >
            <TextInput />
          </FormItem>
          <FormItem
            error={formik.errors.taskstatus}
            label='Datenverarbeitungsstatus Detail'
            name='taskstatus'
            onChange={formik.handleChange}
            value={
              formik.values.taskstatus
                ? m.taskStatusList.gameperiod[formik.values.taskstatus]
                : formik.values.taskstatus
            }
          >
            <TextInput readonly />
          </FormItem>
          <br />
          <RecordInfo data={gameperiod} />
        </FormColumn>
      </Form>
    </div>
  );
}
GameperiodForm.propTypes = propTypes;
