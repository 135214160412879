import {useQuery, useMutation} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';
import gql from 'graphql-tag';
import {useSnackbar, closeSnackbar} from 'notistack';
import React, {useState} from 'react';
import AsyncContent from 'components/AsyncContent/AsyncContent';
import CloseButtonSmall from 'components/CloseButtonSmall/CloseButtonSmall';
import m from '../../messages/de.json';
import UserlistsContent from './UserlistsContent';
import UserlistsTopBar from './UserlistsTopBar';

const query = gql`
  query userlists(
    $where: userlist_bool_exp! = {}
    $limit: Int = 25
    $offset: Int
  ) {
    page: userlist_aggregate(where: $where, limit: 10000) {
      aggregate {
        totalCount: count
      }
    }
    userlists: userlist(where: $where, order_by: {longname: asc}) {
      id
      email
      longname
      userrole
      organisation {
        name
      }
    }
  }
`;

const deleteUserlistMuatation = gql`
  mutation updateUserlist($id: Int!, $deleted: Boolean) {
    update_userlist_by_pk(pk_columns: {id: $id}, _set: {deleted: $deleted}) {
      id
    }
  }
`;

const updatePwdMutation = gql`
  mutation actionAdminPwdSet($userId: Int!, $newPwd: String!) {
    actionAdminPwdSet(userId: $userId, newPwd: $newPwd) {
      code
      message
    }
  }
`;

export default function Userlists() {
  const [filter, setFilter] = useState({
    organisationid: undefined
  });

  const {
    data: resultUserlists,
    error,
    loading,
    refetch
  } = useQuery(query, {
    pollInterval: 60000,
    variables: {
      where: {
        _and: [
          {organisationid: {_eq: filter.organisationid}},
          {deleted: {_eq: false}}
        ]
      }
    }
  });

  const [onDeleteUserlistMutation] = useMutation(deleteUserlistMuatation, {
    ignoreResults: true
  });

  const [onUpdatePwdMuation] = useMutation(updatePwdMutation);

  const {enqueueSnackbar} = useSnackbar();

  async function onDeleteUserlist(userlist) {
    const dUserlist = {id: userlist.id, deleted: true};
    onDeleteUserlistMutation({variables: dUserlist})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingDelete.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingDelete.rejected} (Error: ${error})`, {
          variant: 'error',
          action: (key) => (
            <CloseButtonSmall key={key} onClick={closeSnackbar} />
          )
        });
      });
  }

  async function onPwdSetUserlist(userlist, newPwd) {
    const dUserlist = {userId: userlist.id, newPwd};
    onUpdatePwdMuation({variables: dUserlist})
      .then(() => {
        // console.log('Result-Data: ', data);
        enqueueSnackbar(m.dataHandlingPwdSet.fulfilled, {
          variant: 'success'
        });
        refetch();
      })
      .catch((error) => {
        console.log('Result-Error', error);
        enqueueSnackbar(`${m.dataHandlingPwdSet.rejected} (Error: ${error})`, {
          variant: 'error'
        });
      });
  }

  function onChangeFilter(nextFilter) {
    setFilter((prev) => ({...prev, ...nextFilter}));
  }

  return (
    <>
      <Typography gutterBottom variant='h5'>
        {m.Userlists.title}
      </Typography>
      <UserlistsTopBar filter={filter} onChangeFilter={onChangeFilter} />
      <AsyncContent error={error} loading={loading}>
        <UserlistsContent
          onDeleteUserlist={onDeleteUserlist}
          onPwdSetUserlist={onPwdSetUserlist}
          refetch={refetch}
          resultUserlists={resultUserlists}
        />
      </AsyncContent>
    </>
  );
}
